import { RECEIVE_MERCHANT_SETTINGS } from './constants';
import murmur from 'murmurhash-js';

function getVariantIx(sessionId, variants) {
  const weights = variants.map(variant => variant.weight);
  if (weights.reduce((a, b) => a + b, 0) !== 100) {
    console.error('Sum of weights for variants must be 100');
  }
  const m = murmur.murmur3(sessionId, 0);
  const n = m % 100;

  let lower_bound = 0;

  for (let i = 0; i < variants.length; i++) {
    const v = variants[i];
    const upper_bound = lower_bound + v.weight;

    // If a variant has a weight of 0, ignore it
    if (v.weight > 0 && n < upper_bound) {
      return i;
    }

    lower_bound = upper_bound;
  }
  return variants.length - 1;
}

export function experimentsReducer(state = {}, action) {
  if (action.type === RECEIVE_MERCHANT_SETTINGS) {
    if (action.payload?.experiments) {
      const experimentSettings = action.payload.experiments;
      const experimentPublicId = action.payload.experiments.public_id;

      const { sessionId } = state;
      const variants = experimentSettings.variants;
      const variantIx = getVariantIx(`${experimentPublicId}|${sessionId}`, variants);
      const variant = variants[variantIx];

      return {
        ...state,
        experiments: {
          ...action.payload.experiments,
          currentVariant: variantIx,
          offerProfileId: variant.parameters?.offer_profile_public_id
        }
      };
    }
  }
  return state;
}

export const addExperimentsReducer =
  baseReducer =>
  (prevState = {}, action) => {
    const state = baseReducer(prevState, action);
    return experimentsReducer(state, action);
  };
